.CheckoutPage {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
}

.Header {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
}

.InputRow {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 20px;
}

.Input {
    flex: 1;
}
