.BillListContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
}

.BillListContainerBillContainer {
    font-size: 18px;
    font-weight: 600;
}

.BillListBillHeader {
    font-size: 18px;
    font-weight: 600;
}

.BillListBillSummaryContainer {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
}
