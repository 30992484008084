.CartModal {
    position: absolute;
    top: 60px;
    left: -220px;
    min-height: 100px;
    width: 300px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 0px 5px 3px #0000001e;
    z-index: 1000;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.CartModelContent {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.CartItem {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
}

.CartItemContentLeft {
    margin-right: 8px;
    display: flex;
    flex-direction: column;
}

.CartItemContentMid {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.CartItemContentRight {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;
    color: purple;
}

.CartItemHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
}

.CartItemDetail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CartItemMod {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.CartItemPrice {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 500;
}
