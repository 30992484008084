.SignInPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SignInPage-UserNameInput {
    max-width: 200px;
    margin-bottom: 20px;
}

.SignInPage-PasswordInput {
    max-width: 200px;
    margin-bottom: 10px;
}

.SignInPage-ErrorMessage {
    color: red;
    height: 30px;
}
