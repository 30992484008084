.SelectModComponentContainer {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-top: 8px;
    padding-bottom: 8px;
}

.SelectModComponentTopContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
}

.SelectModComponentName {
    font-size: 20px;
    font-weight: 500;
}

.SelectModComponentHint {
    font-size: 16px;
    font-weight: 500;
}


.SelectModComponentChoicesContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, 48.5%);
    column-gap: 3%;
    row-gap: 8px;
    justify-content: space-between;
    align-content: start;
}

.SelectModComponentChoiceContainer {
    height: 40px;
    margin-top: 8px;
    background-color: #eed7b5;
    border-radius: 8px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px;
}

.SelectModComponentChoiceName {
    font-size: 16px;
    font-weight: 500;
}

.SelectModComponentChoicePrice {
    font-size: 12px;
    font-weight: 500;
}
