.SelectQuantityContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 20px;
    font-weight: 500;
}

.SelectQuantityText {
    margin-left: 6px;
    margin-right: 6px;
}
