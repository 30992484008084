.SelectMenuItemContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.MenuItemContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 156px);
    justify-content: space-around;
}
