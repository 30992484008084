.MenuItemButtonContainer {
    width: 140px;
    height: 148px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.MenuItemImage {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
}

.MenuItemName {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}
