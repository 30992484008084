.Title {
    font-size: 24px;
    font-weight: 500;
}

.SubTitle {
    font-size: 18px;
    font-weight: 500;
}

.ContentContainer {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
}
