.HeaderBar {
    width: 100%;
    height: 60px;
    border-bottom: 4px solid black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.HeaderBarContentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-left: 40px;
    margin-right: 40px;
    gap: 20px;
}
