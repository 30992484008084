.TableInfoModalContent {
    min-height: 100%;
    background-color: rgb(230, 230, 230);
}

.TableInfoModalContentHeader {
    font-size: 36;
    font-weight: 600;
    margin-bottom: 8px;
}
