.ButtonContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: burlywood;
    padding: 10px;
    border-radius: 8px;
}

.ButtonSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
}
