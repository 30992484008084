.SellerApp {
    background-color: #f1eac7;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: horizontal;
    align-items: center;
    justify-content: center;
}

.PageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.PageContent {
    flex: 1;
    width: 100%;
    overflow-y: hidden;
}
