.IconButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: burlywood;
    padding: 8px;
    border-radius: 8px;
}

.IconButtonImage {
    width: 16px;
    height: 16px;
}
