.CreateOrderStatusContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
}

.CreateOrderSummaryContainer {
    flex: 1;
    margin-bottom: 20px;
}

.CreateOrderStatus-ErrorMessage {
    color: red;
    height: 30px;
}
