.CenterMaxWidthContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.CenterMaxWidth {
    width: 100%;
}
