.NewTakeoutModalContent {
    height: 100%;
    background-color: rgb(230, 230, 230);
    display: flex;
    flex-direction: column;
}

.NewTakeoutModalInputRow {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.NewTakeoutModalInput {
    flex: 1;
}
