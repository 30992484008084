.CartButton {
    position: relative;
    padding: 10px;
    margin: 10px;
    width: 80px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.CartButtonImage {
    width: 50px;
    height: 50px;
}

.CartButtonCount {
    position: absolute;
    top: 5px;
    left: 70px;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: red;
    color: white;
    text-align: center;
    font-size: 8;
    font-weight: 700;
}

.CartModalBackground {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
}
