.HomePage {
    background-color: #f1eac7;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.HomePageRestaurantsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.HomePageRestaurantButtonContainer {
    margin-bottom: 20px;
}

.HomePageSellerButtonContainer {
    height: 150px;
    padding-top: 50px;
}
