.NewDeliveryModalContent {
    height: 100%;
    background-color: rgb(230, 230, 230);
    display: flex;
    flex-direction: column;
}

.NewDeliveryModalInputRow {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.NewDeliveryModalInput {
    flex: 1;
}
