.SelectMenuItemModContainer {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.SelectMenuItemModTopBar {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 2px solid black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: 20px;
    font-weight: 500;
}

.SelectMenuItemModTopBarGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.SelectMenuItemModTopBarBackIcon {
    width: 80px;
    height: 40px;
}

.SelectMenuItemModPrice {
    margin-right: 12px;
    font-size: 24px;
    font-weight: 500;
}

.SelectMenuItemModContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    gap: 8px;
    overflow-y: auto;
}

.ModSeparator {
    margin-left: 16px;
    margin-right: 16px;
    height: 1px;
    background-color: #595959
}
