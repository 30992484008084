.ModalContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.Modal {
    flex: 1;
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 500px;
    background-color: white;
    border-radius: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 1000;
    box-shadow: 0px 0px 5px 3px #0000001e;
}
