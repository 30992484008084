.BottomBarModalContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.BottomBarModalContent {
    flex: 1;
    overflow-y: auto;
}

.BottomBarModalButtonContainers {
    margin-top: 10px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.BottomBarModalButtonContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
